import * as React from "react";
import { Col, Row } from "reactstrap";
import ContentSection from "../../components/contentSection";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import setiImage from "../../images/seti-contract-holder.png";

const DisaSeti: React.FunctionComponent = () => (
    <Layout>
        <SEO title="DISA SETI" />

        <ContentSection narrow>
            <h1 className="text-center">DISA SETI</h1>
        </ContentSection>

        <ContentSection title="About SETI" small>
            <Row>
                <Col
                    xs={{
                        size: 12,
                        order: "last"
                    }}
                    sm={{
                        size: 12,
                        order: "last"
                    }}
                    md={{
                        size: 12,
                        order: "last"
                    }}
                    lg={{
                        size: 8,
                        order: 1
                    }}
                    xl={{
                        size: 10,
                        order: 1
                    }}
                >
                    <p>
                        SETI is a contract vehicle that has a focused scope to
                        provide new, non-commodity and innovative information
                        technology engineering solutions, embracing cutting-edge
                        technologies and methodologies for mission partners
                        across the Department of Defense (DoD). SETI is a
                        multiple award, indefinite-delivery/indefinite-quantity
                        (IDIQ) vehicle designed for current complex and unique
                        requirements, but also built to be technology-agnostic
                        in order to solve capability gaps in all future mission
                        needs.
                    </p>
                </Col>

                <Col
                    xs={{
                        size: 12,
                        order: "first"
                    }}
                    sm={{
                        size: 12,
                        order: "first"
                    }}
                    md={{
                        size: 12,
                        order: "first"
                    }}
                    lg={{
                        size: 4,
                        order: 2
                    }}
                    xl={{
                        size: 2,
                        order: 2
                    }}
                >
                    <img
                        src={setiImage}
                        alt="DISA SETI Contract Holder"
                        className="d-block mb-4 mt-3 mb-lg-0 mx-auto"
                    />
                </Col>
            </Row>
        </ContentSection>

        <ContentSection
            title="Key Benefits"
            backgroundColor="secondary"
            textColor="white"
        >
            <ul>
                <li>DoD-Wide Use: All DoD, DISA, and DISA mission partners.</li>
                <li>
                    Decentralized Ordering: Task Orders may be issued by any DoD
                    Contracting Activity or by the Defense Information
                    Technology Contracting Organization
                </li>
                <li>
                    Performance-Based Cose, T&amp;M, FFP, and Cost Reimbursement
                    (CR) Task Orders
                </li>
            </ul>
        </ContentSection>

        <ContentSection title="Overview: HC1047-19-D-2040">
            <div className="mb-4">
                <h3 className="h4">Customers</h3>
                <p>All DoD, DISA, and DISA mission partners.</p>
            </div>

            <div className="mb-4">
                <h3 className="h4">Mission</h3>
                <p>
                    SETI provides an overarching streamlined process for
                    ordering a wide variety of critical IT engineering
                    performance-based services while ensuring consistency and
                    maximum opportunity for competition. It is a crucial vehicle
                    for enabling the Agency and its partners to address the
                    growing and dynamic engineering and technical needs of
                    today’s and tomorrow’s Warfighter in a more streamlined and
                    cost-effective approach. SETI promotes the delivery of
                    innovative systems and capabilities using mature and
                    emerging technologies and standards in order to achieve and
                    improve towards a collaborative, adaptive, secure,
                    expeditious, and interoperable enterprise information
                    environment.
                </p>
            </div>

            <div className="mb-4">
                <h3 className="h4">Period of Performance</h3>
                <p>June 2020&ndash;May 2025, + One Five-Year Option</p>
            </div>

            <div className="mb-4">
                <h3 className="h4">$7.5B Pooled Capacity</h3>
                <p>No size limit on Task Orders</p>
            </div>

            <div className="mb-4">
                <h3 className="h4">Contract Types</h3>
                <p>
                    All allowed (FFP/CR/T&amp;M/Hybrids) &mdash; 96 labor
                    categories.
                </p>
            </div>

            <h3 className="h4">Performance Areas</h3>

            <ul className="list-group">
                <li className="list-group-item">
                    Task Area 1: System Engineering
                </li>
                <li className="list-group-item">
                    Task Area 2: Design Analysis Engineering
                </li>
                <li className="list-group-item">
                    Task Area 3: Systems Architecture
                </li>
                <li className="list-group-item">
                    Task Area 4: Software Systems Design and Development
                </li>
                <li className="list-group-item">
                    Task Area 5: Systems Integration
                </li>
                <li className="list-group-item">
                    Task Area 6: Systems Test and Evaluation
                </li>
                <li className="list-group-item">
                    Task Area 7: Systems Deployment and Lifecycle Engineering
                </li>
                <li className="list-group-item">
                    Task Area 8: Special Systems Engineering Requirements
                </li>
            </ul>
        </ContentSection>

        <div className="mb-5">
            <ContentSection backgroundColor="secondary" textColor="white">
                <p className="text-center mb-0">
                    To contact the SETI Small Business team, please email{" "}
                    <a href="mailto:SETI@tibercreek.com" className="text-reset">
                        SETI@tibercreek.com
                    </a>
                </p>
            </ContentSection>
        </div>
    </Layout>
);

export default DisaSeti;
